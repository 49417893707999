import { lazy } from 'react';
import EmptyLayout from 'layout/EmptyLayout/EmptyLayout';
import AgreementLayout from 'layout/EmptyLayout';
import Loadable from 'ui-component/Loadable';

const PrivacyAgreement = Loadable(lazy(() => import('views/PrivacyAgreement')));
const UserAgreement = Loadable(lazy(() => import('views/UserAgreement')));
const Maintain = Loadable(lazy(() => import('views/Maintain')));
const EnterpriseAuth = Loadable(lazy(() => import('views/Identification/EnterpriseAuth')));
const PersonalAuth = Loadable(lazy(() => import('views/Identification/PersonalAuth')));

export const AgreementRoutes = {
  path: '/',
  element: <AgreementLayout />,
  children: [
    {
      path: 'agreement/privacy',
      element: <PrivacyAgreement />
    },
    {
      path: 'agreement/user',
      element: <UserAgreement />
    },
    {
      path: 'enterprise/auth',
      element: <EnterpriseAuth />
    },
    {
      path: 'personal/auth',
      element: <PersonalAuth />
    }
  ]
};
export const EmptyLayoutRoutes = {
  path: '/',
  element: <EmptyLayout />,
  children: [
    {
      path: 'maintain',
      element: <Maintain />
    }
  ]
};
