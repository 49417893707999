import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import styles from './NavCollapse.module.scss';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import * as actionTypes from 'store/actions';
// project imports
import NavItem from '../NavItem';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { Popover } from 'antd';

const NavCollapse = ({ menu, level }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const navigate = useNavigate();
  const isShutSiderBar = useSelector((state) => state.customization.opened);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const listItemButtonStyles = {
    width: '100%',
    display: 'flex',
    flexDirection: isShutSiderBar ? 'row' : 'column',
    fontSize: isShutSiderBar ? '14px' : '11px',
    padding: isShutSiderBar ? undefined : '12px 6px',
    alignItems: 'center',
    textAlign: isShutSiderBar ? undefined : 'center',
    whiteSpace: 'nowrap'
  };
  const navItemTextStyles = {
    fontSize: isShutSiderBar ? '14px' : '11px',
    textAlign: isShutSiderBar ? undefined : 'center',
    whiteSpace: 'nowrap'
  };
  const handleClick = () => {
    setOpen(!open);
    setSelected(!selected ? menu.id : null);
    const id = menu.children[0].id;
    if (open) {
      dispatch({ type: actionTypes.MENU_OPEN, id: menu.id });
    } else {
      navigate(menu.children[0]?.url);
      dispatch({ type: actionTypes.MENU_OPEN, id });
    }
  };

  const { pathname } = useLocation();
  const checkOpenForParent = (child, id) => {
    child.forEach((item) => {
      if (item.url === pathname) {
        setOpen(true);
        setSelected(id);
      }
    });
  };

  // menu collapse for sub-levels
  useEffect(() => {
    setOpen(false);
    setSelected(null);
    if (menu.children) {
      menu.children.forEach((item) => {
        if (item.children?.length) {
          checkOpenForParent(item.children, menu.id);
        }
        if (item.url === pathname) {
          setSelected(menu.id);
          setOpen(true);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menu.children]);

  // menu collapse & item
  const Menus = () => {
    return (
      <>
        {menu.children?.map((item) => {
          switch (item.type) {
            case 'collapse':
              return <NavCollapse key={item.id} menu={item} level={level + 1} />;
            case 'item':
              return (
                <>
                  <NavItem className={!isShutSiderBar ? styles.minNavItem : ''} key={item.id} item={item} level={level + 1} />
                </>
              );
            default:
              return (
                <Typography key={item.id} variant="h6" color="error" align="center">
                  Menu Items Error
                </Typography>
              );
          }
        })}
      </>
    );
  };

  const Icon = menu.icon;
  const menuIcon = menu.icon ? (
    <Icon strokeWidth={1.5} size="1.3rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: selected === menu.id ? 8 : 6,
        height: selected === menu.id ? 8 : 6
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );
  const item = (
    <ListItemButton
      sx={{
        borderRadius: `${customization.borderRadius}px`,
        mb: 0.5,
        alignItems: 'flex-start',
        backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
        py: level > 1 ? '5px' : '5px',
        pl: `${level * 12}px`,
        ...listItemButtonStyles
      }}
      selected={selected === menu.id}
      onClick={handleClick}
    >
      <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 30, justifyContent: 'center' }}>{menuIcon}</ListItemIcon>
      <ListItemText
        primary={
          <Typography variant={selected === menu.id ? 'h5' : 'body1'} color="inherit" sx={{ my: 'auto', ...navItemTextStyles }}>
            {menu.title}
          </Typography>
        }
        secondary={
          menu.caption && (
            <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption, ...navItemTextStyles }} display="block" gutterBottom>
              {menu.caption}
            </Typography>
          )
        }
      />
      {isShutSiderBar ? (
        <>
          {open ? (
            <IconChevronUp stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto', marginRight: '14px' }} />
          ) : (
            <IconChevronDown stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto', marginRight: '14px' }} />
          )}
        </>
      ) : (
        ''
      )}
    </ListItemButton>
  );
  const list = (
    <List
      component="div"
      disablePadding
      sx={{
        position: 'relative'
      }}
    >
      <Menus />
    </List>
  );
  return (
    <>
      {!isShutSiderBar ? (
        <Popover overlayClassName={styles.popoverInner} placement="right" content={list}>
          {item}
        </Popover>
      ) : (
        <>{item}</>
      )}
      {isShutSiderBar && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {list}
        </Collapse>
      )}
    </>
  );
};

NavCollapse.propTypes = {
  menu: PropTypes.object,
  level: PropTypes.number
};

export default NavCollapse;
