import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useHeaderVisibility = () => {
  const location = useLocation();
  const noHeaderPaths = ['/', '/fullScreen'];
  const [shouldDisplayHeader, setShouldDisplayHeader] = useState(true);

  useEffect(() => {
    const isPathInNoHeaderPaths = noHeaderPaths.includes(location.pathname);

    setShouldDisplayHeader(!isPathInNoHeaderPaths);
  }, [location.pathname]);

  return shouldDisplayHeader;
};

export { useHeaderVisibility };
