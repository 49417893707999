// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import { getMenuItems } from 'menu-items/profile';
import { isAdmin, isRoot } from 'utils/common';

// ==============================|| SIDEBAR MENU LIST ||============================== //
const MenuList = () => {
  const userIsAdmin = isAdmin();
  const userIsRoot = isRoot();
  const menuItem = getMenuItems();

  return (
    <>
      {menuItem.items.map((item) => {
        if (item.type !== 'group') {
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
        }

        const filteredChildren = item.children
          .filter((child) => !child.isAdmin || userIsAdmin)
          .filter((child) => !child.isRoot || userIsRoot);

        if (filteredChildren.length === 0) {
          return null;
        }

        return <NavGroup key={item.id} item={{ ...item, children: filteredChildren }} />;
      })}
    </>
  );
};

export default MenuList;
