import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { SET_THEME } from 'store/actions';
import useAuthRedirect from 'hooks/useAuthRedirect';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import 'highlight.js/styles/xcode.css';
// routing
import Routes from 'routes';
// defaultTheme
// import themes from 'themes';
import themes from 'themes-new'; //修改处

// project imports
import NavigationScroll from 'layout/NavigationScroll';

// auth
import UserProvider from 'contexts/UserContext';
import StatusProvider from 'contexts/StatusContext';
import { SnackbarProvider } from 'notistack';

// ==============================|| APP ||============================== //

const App = () => {
  const dispatch = useDispatch();
  const customization = useSelector((state) => state.customization);

  useEffect(() => {
    dispatch({ type: SET_THEME, theme: 'light' });
  }, [dispatch]);

  useEffect(() => {
    // 检查 FrontendTracker 是否加载成功
    if (window.DataTracker) {
      // 初始化埋点 SDK
      new window.DataTracker("/tracking/");
    } else {
      console.error("FrontendTracker SDK not loaded");
    }
  }, []);

  useAuthRedirect();
  return (
    <ConfigProvider locale={zhCN}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes(customization)}>
          <CssBaseline />

          <NavigationScroll>
            <SnackbarProvider autoHideDuration={5000} maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
              <UserProvider>
                <StatusProvider>
                  <Routes />
                </StatusProvider>
              </UserProvider>
            </SnackbarProvider>
          </NavigationScroll>
        </ThemeProvider>
      </StyledEngineProvider>
    </ConfigProvider>
  );
};

export default App;
