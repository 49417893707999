import { enqueueSnackbar } from 'notistack';
import { snackbarConstants } from 'constants/SnackbarConstants';
import { API } from './api';
import Decimal from 'decimal.js';
import { message as antdMessage } from 'antd';
export function getSystemName() {
  let system_name = localStorage.getItem('system_name');
  if (!system_name) return 'PerfXCloud';
  return system_name;
}

export function isMobile() {
  return window.innerWidth <= 600;
}

// eslint-disable-next-line
export function SnackbarHTMLContent({ htmlContent }) {
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
}

export function getSnackbarOptions(variant) {
  let options = snackbarConstants.Common[variant];
  if (isMobile()) {
    // 合并 options 和 snackbarConstants.Mobile
    options = { ...options, ...snackbarConstants.Mobile };
  }
  return options;
}

export function showError(error, showTip = true) {
  if (error.message) {
    if (error.name === 'AxiosError') {
      switch (error.response.status) {
        case 429:
          // enqueueSnackbar('错误：请求次数过多，请稍后再试！', getSnackbarOptions('ERROR'));
          antdMessage.error('错误：请求次数过多，请稍后再试！');
          break;
        case 500:
          antdMessage.error('错误：服务器内部错误，请联系管理员！');
          break;
        case 405:
          antdMessage.error('本站仅作演示之用，无服务端！');
          break;
        default:
          antdMessage.error((showTip ? '错误：' : '') + error.message);
      }
      return;
    }
  } else {
    antdMessage.error((showTip ? '错误：' : '') + error);
  }
}

export function showNotice(message, isHTML = false) {
  if (isHTML) {
    antdMessage.info(<SnackbarHTMLContent htmlContent={message} />);
  } else {
    antdMessage.info(message); //getSnackbarOptions('NOTICE')
  }
}

export function showWarning(message) {
  antdMessage.warning(message);
  // enqueueSnackbar(message, getSnackbarOptions('WARNING'));
}

export function showSuccess(message) {
  antdMessage.success(message);
  //enqueueSnackbar(message, getSnackbarOptions('SUCCESS'));
}

export function showInfo(message) {
  antdMessage.info(message);
}

export async function getOAuthState() {
  const res = await API.get('/api/oauth/state');
  const { success, message, data } = res.data;
  if (success) {
    return data;
  } else {
    showError(message);
    return '';
  }
}

export async function onGitHubOAuthClicked(github_client_id, openInNewTab = false) {
  const state = await getOAuthState();
  if (!state) return;
  let url = `https://github.com/login/oauth/authorize?client_id=${github_client_id}&state=${state}&scope=user:email`;
  if (openInNewTab) {
    window.open(url);
  } else {
    window.location.href = url;
  }
}

export async function onLarkOAuthClicked(lark_client_id) {
  const state = await getOAuthState();
  if (!state) return;
  let redirect_uri = `${window.location.origin}/oauth/lark`;
  window.open(`https://open.feishu.cn/open-apis/authen/v1/index?redirect_uri=${redirect_uri}&app_id=${lark_client_id}&state=${state}`);
}

export function isAdmin() {
  let user = localStorage.getItem('userinfo');
  if (!user) return false;
  user = JSON.parse(user);
  return user.role >= 10;
}
export function isRoot() {
  let user = localStorage.getItem('userinfo');
  if (!user) return false;
  user = JSON.parse(user);
  return user.role >= 100;
}
export function isShowTeamMenu() {
  return true;
}
export function isShowFinetuneMenu() {
  let user = localStorage.getItem('userinfo');
  if (!user) return false;
  user = JSON.parse(user);
  return user.group == 'svip';
}
export function setSystemOptions(options) {
  localStorage.setItem('system/options', JSON.stringify(options));
}
export function getSystemOptions() {
  const objJson = localStorage.getItem('system/options');
  let data = undefined;
  if (objJson) {
    try {
      data = JSON.parse(objJson);
    } catch (error) {}
  }
  return data;
}
export function updateSystemOptions(key, value) {
  let data = getSystemOptions();
  if (data) {
    data[key] = value;
    setSystemOptions(data);
  }
}
export function getUserInfo() {
  let user = localStorage.getItem('userinfo');
  if (!user) return null;
  user = JSON.parse(user);
  return user;
}
export function updateUserInfo(key, value) {
  let user = localStorage.getItem('userinfo');
  if (!user) return null;
  user = JSON.parse(user);
  user[key] = value;
  localStorage.setItem('userinfo', JSON.stringify(user));
  return user;
}
export function timestamp2string(timestamp) {
  let date = new Date(timestamp * 1000);
  let year = date.getFullYear().toString();
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();
  let hour = date.getHours().toString();
  let minute = date.getMinutes().toString();
  let second = date.getSeconds().toString();
  if (month.length === 1) {
    month = '0' + month;
  }
  if (day.length === 1) {
    day = '0' + day;
  }
  if (hour.length === 1) {
    hour = '0' + hour;
  }
  if (minute.length === 1) {
    minute = '0' + minute;
  }
  if (second.length === 1) {
    second = '0' + second;
  }
  return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
}

export function calculateQuota(quota, digits = 2) {
  let quotaPerUnit = localStorage.getItem('quota_per_unit');
  quotaPerUnit = parseFloat(quotaPerUnit);

  return (quota / quotaPerUnit).toFixed(digits);
}

export function renderQuota(quota, digits = 2) {
  let displayInCurrency = localStorage.getItem('display_in_currency');
  displayInCurrency = displayInCurrency === 'true';
  if (displayInCurrency) {
    return calculateQuota(quota, digits);
  }
  return renderNumber(quota);
}

export const verifyJSON = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export function renderNumber(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1) + 'B';
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else if (num >= 10000) {
    return (num / 1000).toFixed(1) + 'k';
  } else {
    return num;
  }
}

export function renderQuotaWithPrompt(quota, digits) {
  let displayInCurrency = localStorage.getItem('display_in_currency');
  displayInCurrency = displayInCurrency === 'true';
  if (displayInCurrency) {
    return `（等价金额：${calculateQuota(quota, digits)}）`;
  }
  return '';
}

export function downloadTextAsFile(text, filename) {
  let blob = new Blob([text], { type: 'text/plain;charset=utf-8' });
  let url = URL.createObjectURL(blob);
  let a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
}

export function removeTrailingSlash(url) {
  if (url.endsWith('/')) {
    return url.slice(0, -1);
  } else {
    return url;
  }
}
export const formatDateTime = (timestamp, offset = 0, type = 'date', space = '/') => {
  // 创建一个新的 Date 对象，使用给定的时间戳
  const date = new Date(timestamp);

  // 应用天数偏移量
  date.setDate(date.getDate() + offset);

  // 验证 Date 对象是否有效
  if (isNaN(date.getTime())) {
    return timestamp;
  }
  // 提取日期和时间的各个部分
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份从 0 开始，因此需要 +1，并确保两位数格式
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  // 格式化输出字符串
  const formattedDate =
    type == 'datetime' ? `${year}${space}${month}${space}${day} ${hours}:${minutes}:${seconds}` : `${year}${space}${month}${space}${day}`;
  return formattedDate;
};

// 使用示例：

export function copyToClipboard(text) {
  if (navigator.clipboard) {
    // 支持 navigator.clipboard 的环境
    return navigator.clipboard.writeText(text);
  } else {
    // 使用 document.execCommand 在不支持 navigator.clipboard 的环境下复制文本
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // 将 textarea 元素添加到 DOM 中
    document.body.appendChild(textArea);

    // 选中文本并执行复制命令
    textArea.select();
    document.execCommand('copy');

    // 移除 textarea 元素
    document.body.removeChild(textArea);

    // 返回 Promise 对象
    return Promise.resolve();
  }
}

export function getItemAriaLabel(type, page, selected, itemCount) {
  switch (type) {
    case 'first':
      return '前往第一页';
    case 'last':
      return '前往最后一页';
    case 'next':
      return '前往下一页';
    case 'previous':
      return '前往上一页';
    default:
      return '';
  }
}
export function isValidPhoneNumber(phone) {
  const phoneRegex = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
  return phoneRegex.test(phone);
}
export const multiplyAndCeil = (number, multiple) => {
  return new Decimal(number)
    .times(new Decimal(multiple || 1))
    .ceil()
    .toNumber();
};
export const addNumbers = (num, offset) => {
  const result = new Decimal(num).plus(new Decimal(offset || 0));
  return Math.ceil(result.toNumber());
};
export function generateShortId(length = 8) {
  return Math.random().toString(36).substr(2, length);
}
/**
 * 获取当前日期的月份和日期
 * @returns {Object} 包含月份和日期的对象
 */
export function getCurrentMonthAndDay(date) {
  // 获取当前日期对象
  const newDate = new Date(date);

  // 获取月份（0-11，0表示1月，11表示12月），加1使其范围为1-12
  const month = newDate.getMonth() + 1;

  // 获取日期（1-31）
  const day = newDate.getDate();

  // 返回包含月份和日期的对象
  return `${month}/${day}`;
}

export const roundAndTwodecimal = (number) => {
  return Math.round(number * 100) / 100;
};

export const getDomain = () => {
  const protocol = window.location.protocol; // 'https:'
  const domain = window.location.hostname; // 'www.example.com'
  const port = window.location.port; // '443' (如果没有端口则为空字符串)
  const protocolDomainPort = `${protocol}//${domain}${port ? `:${port}` : ''}`;
  return protocolDomainPort;
};

export function formatNumber(num) {
  if (!num) return 0;

  // 将数字转换为绝对值，处理负数
  const absoluteNum = Math.abs(num);

  // 大于 1000 时使用 'k'
  if (absoluteNum >= 1000) {
    // 计算以千为单位的值
    let formattedNum = (num / 1000).toFixed(1); // 保留一位小数
    return formattedNum.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'k';
  } else {
    // 小于 1000 的情况，直接格式化
    let str = num.toString();

    // 判断是否有小数点
    if (str.indexOf('.') !== -1) {
      let parts = str.split('.');
      // 处理整数部分
      let integerPart = parts[0];
      let formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      // 处理小数部分
      let decimalPart = parts[1];
      return formattedInteger + '.' + decimalPart;
    } else {
      // 没有小数点的情况
      return str.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  }
}

export function hidePhoneNumber(phoneNumber) {
  if (!phoneNumber) return '';
  const prefix = phoneNumber.slice(0, 3);
  const suffix = phoneNumber.slice(-4);
  return `${prefix}****${suffix}`;
}

export function randomColor() {
  const colors = ['#3A58FF', '#8844EB', '#3CC6C7', '#F09748', '#E54545'];

  const randomColor = colors[Math.floor(Math.random() * colors.length)];
  return randomColor;
}
