// assets
import {
  IconChartArea,
  IconMessage,
  IconPictureInPicture,
  IconMenuOrder,
  IconChartDots,
  IconCertificate,
  IconSquares,
  IconChartBar,
  IconDeviceLaptop,
  IconTicket,
  IconServer
} from '@tabler/icons-react';
import {
  IconDashboard,
  IconSitemap,
  IconArticle,
  IconCoin,
  IconAdjustments,
  IconKey,
  IconGardenCart,
  IconUser,
  IconUserScan,
  IconLayoutGrid,
  IconDevicesPlus,
  IconUserHeart,
  IconBoxModel,
  IconTag,
  IconSettingsBolt,
  IconDeviceAudioTape,
  IconTemplate,
  IconRobot,
  IconDatabaseLeak
} from '@tabler/icons-react';
import { isShowFinetuneMenu } from 'utils/common';
import ZonglanSvg from 'assets/images/menus/zonglan.svg';
import ZonglanActiveSvg from 'assets/images/menus/zonglan.svg';
import GuangchangSvg from 'assets/images/menus/guangchuang.svg';
import GuangchangActiveSvg from 'assets/images/menus/guangchang_active.svg';
import TiyanSvg from 'assets/images/menus/tiyan.svg';
import TiyanActiveSvg from 'assets/images/menus/tiyan.svg';
import WeitiaoSvg from 'assets/images/menus/weitiao.svg';
import WeitiaoActiveSvg from 'assets/images/menus/weitiao_active.svg';
import FuwuSvg from 'assets/images/menus/fuwu.svg';
import FuwuActiveSvg from 'assets/images/menus/fuwu_active.svg';
import LingpaiSvg from 'assets/images/menus/lingpai.svg';
import LingpaiActiveSvg from 'assets/images/menus/lingpai_active.svg';
import TongjiSvg from 'assets/images/menus/tongji.svg';
import TongjiActiveSvg from 'assets/images/menus/tongji_active.svg';

// constant
const icons = {
  IconDashboard,
  IconLayoutGrid,
  IconSitemap,
  IconArticle,
  IconCoin,
  IconAdjustments,
  IconKey,
  IconGardenCart,
  IconUser,
  IconUserScan,
  IconDevicesPlus,
  IconChartArea,
  IconTemplate,
  IconRobot,
  IconDatabaseLeak
};

const getPanel = () => {
  return {
    id: 'panel',
    type: 'group',
    children: [
      {
        id: 'dashboard',
        title: '用户总览',
        type: 'item',
        url: '/panel/dashboard/index',
        // icon: overview,
        icon: icons.IconDashboard,
        imgIcon: ZonglanSvg,
        imgActiveIcon: ZonglanActiveSvg,
        breadcrumbs: false,
        isAdmin: false,
        group: ''
      },
      {
        id: 'modelsquare',
        title: '模型广场',
        type: 'item',
        url: '/panel/modelsquare/index',
        icon: icons.IconLayoutGrid,
        imgIcon: GuangchangSvg,
        imgActiveIcon: GuangchangActiveSvg,
        breadcrumbs: false,
        isAdmin: false,
        group: '模型'
      },
      {
        id: 'exploration_center',
        title: '体验中心',
        type: 'collapse',
        // type: 'item',
        // url: '/panel/exploration_center',
        icon: IconDeviceLaptop,
        imgIcon: TiyanSvg,
        imgActiveIcon: TiyanActiveSvg,
        breadcrumbs: false,
        isAdmin: false,
        children: [
          {
            id: 'chat_bot',
            title: '对话聊天',
            type: 'item',
            url: '/panel/exploration_center/index',
            icon: IconMessage,
            breadcrumbs: false,
            isAdmin: false
          },
          {
            id: 'text2image',
            title: '文生图',
            type: 'item',
            url: '/panel/exploration_center/text2img/index',
            icon: IconPictureInPicture,
            breadcrumbs: false,
            isAdmin: false
          },
          {
            id: 'text2language',
            title: '文生语音',
            type: 'item',
            url: '/panel/exploration_center/text2voice/index',
            icon: IconDeviceAudioTape,
            breadcrumbs: false,
            isAdmin: false
          }
        ],
        group: '模型'
      },
      {
        id: 'cuewordproject',
        title: '提示词优化',
        type: 'item',
        url: 'cueWordproject/index',
        icon: icons.IconTemplate,
        imgIcon: GuangchangSvg,
        imgActiveIcon: GuangchangActiveSvg,
        breadcrumbs: false,
        isAdmin: false,
        group: '模型'
      },
    //   {
    //     id: 'datasets',
    //     title: '知识库',
    //     type: 'item',
    //     url: 'datasets/index',
    //     icon: icons.IconDatabaseLeak,
    //     imgIcon: GuangchangSvg,
    //     imgActiveIcon: GuangchangActiveSvg,
    //     breadcrumbs: false,
    //     isAdmin: false,
    //     group: '模型'
    //   },
    //   {
    //     id: 'agent',
    //     title: '智能体',
    //     type: 'item',
    //     url: 'agent/index',
    //     icon: icons.IconRobot,
    //     imgIcon: GuangchangSvg,
    //     imgActiveIcon: GuangchangActiveSvg,
    //     breadcrumbs: false,
    //     isAdmin: false,
    //     group: '模型'
    //   },
      ...(isShowFinetuneMenu()
        ? [
            {
              id: 'model-adjustment',
              title: '模型微调',
              type: 'item',
              url: '/panel/model/fine_tuning_pro',
              icon: IconSettingsBolt,
              imgIcon: WeitiaoSvg,
              imgActiveIcon: WeitiaoActiveSvg,
              breadcrumbs: false,
              isAdmin: false,
              disabled: false,
              group: '模型'
            },
            {
              id: 'model-deploy',
              title: '模型服务',
              type: 'item',
              url: '/panel/model/fine_service',
              icon: IconServer,
              imgIcon: FuwuSvg,
              imgActiveIcon: FuwuActiveSvg,
              breadcrumbs: false,
              disabled: false,
              isAdmin: false,
              group: '模型'
            }
          ]
        : []),

      // {
      //   id: 'user_index_distribution',
      //   title: '数据面板管理',
      //   type: 'item',
      //   url: '/panel/user_index_distribution',
      //   icon: IconEaseInControlPoint,
      //   breadcrumbs: false,
      //   isAdmin: true
      // },
      {
        id: 'token',
        title: '令牌管理',
        type: 'item',
        url: '/panel/token/index',
        icon: icons.IconKey,
        imgIcon: LingpaiSvg,
        imgActiveIcon: LingpaiActiveSvg,
        breadcrumbs: false,
        group: '模型'
      },

      {
        id: 'usage/index',
        title: '用量统计',
        type: 'item',
        url: '/panel/usage/index',
        icon: IconChartBar,
        imgIcon: TongjiSvg,
        imgActiveIcon: TongjiActiveSvg,
        breadcrumbs: false,
        isAdmin: false,
        group: '模型'
      },
      {
        id: 'channel',
        title: '端点',
        type: 'item',
        url: '/panel/channel/index',
        icon: icons.IconSitemap,
        breadcrumbs: false,
        isAdmin: true,
        group: '设置'
      },

      {
        id: 'log',
        title: '日志',
        type: 'item',
        url: '/panel/log/index',
        icon: icons.IconArticle,
        breadcrumbs: false,
        isAdmin: true,
        group: '设置'
      },
      {
        id: 'redemption',
        title: '兑换',
        type: 'item',
        url: '/panel/redemption/index',
        icon: IconTicket,
        breadcrumbs: false,
        isAdmin: true,
        group: '设置'
      },
      {
        id: 'statistics',
        title: '数据统计',
        type: 'item',
        url: '/panel/statistics/index',
        icon: IconChartDots,
        breadcrumbs: false,
        isAdmin: true,
        group: '设置'
      },
      {
        id: 'modelsquare-manage',
        title: '模型广场管理',
        type: 'item',
        url: '/panel/modelsquare-manage/index',
        icon: IconBoxModel,
        breadcrumbs: false,
        isAdmin: true,
        group: '设置'
      },
      {
        id: 'auth-enterprise-manage',
        title: '企业认证管理',
        type: 'item',
        url: '/panel/auth/enterprise/manage/index',
        icon: IconCertificate,
        breadcrumbs: false,
        isAdmin: true,
        group: '设置'
      },
      {
        id: 'modeltag/manage',
        title: '模型标签管理',
        type: 'item',
        url: '/panel/modeltag/manage/index',
        icon: IconTag,
        breadcrumbs: false,
        isAdmin: true,
        group: '设置'
      },
      // {
      //   id: 'order/manage',
      //   title: '订单管理',
      //   type: 'item',
      //   url: '/panel/order/manage',
      //   icon: IconMenuOrder,
      //   breadcrumbs: false,
      //   isAdmin: false
      // },
      {
        id: 'user',
        title: '用户',
        type: 'item',
        url: '/panel/user/index',
        icon: icons.IconUser,
        breadcrumbs: false,
        isAdmin: true,
        group: '设置'
      },
      // ...(isShowTeamMenu()
      //   ? [
      //       {
      //         id: 'team_manage',
      //         title: '团队管理',
      //         type: 'item',
      //         url: '/panel/team_manage',
      //         icon: IconBoxModel,
      //         breadcrumbs: false,
      //         isAdmin: false,
      //         group: '设置'
      //       }
      //     ]
      //   : []),
      // {
      //   id: 'profile',
      //   title: '我的',
      //   type: 'item',
      //   url: '/panel/profile/index',
      //   icon: icons.IconUserScan,
      //   breadcrumbs: false,
      //   isAdmin: true,
      //   group: '设置'
      // },
      {
        id: 'setting',
        title: '设置',
        type: 'item',
        url: '/panel/setting/index',
        icon: icons.IconAdjustments,
        breadcrumbs: false,
        isAdmin: true,
        isRoot: true,
        group: '设置'
      }
    ]
  };
};

export { getPanel };
