import { isShowTeamMenu } from 'utils/common';
import { IconUsers, IconClipboardData, IconFileDescription, IconCoinYen, IconUser } from '@tabler/icons-react';
import rights from 'assets/images/users/rights.svg'

const getProfileMenu = () => {
  return {
    id: 'panel',
    type: 'group',
    children: [
      {
        id: 'profile',
        title: '账户设置',
        type: 'item',
        url: '/profile/index',
        icon: IconUser,
        breadcrumbs: false,
        isAdmin: false
      },
      {
        id: 'topup',
        title: '充值兑换',
        type: 'item',
        url: '/profile/topup/index',
        icon: IconCoinYen,
        breadcrumbs: false
      },
      {
        id: 'order',
        title: '账单明细',
        type: 'item',
        url: '/profile/order/manage/index',
        icon: IconFileDescription,
        breadcrumbs: false
      },
      {
        id: 'consume',
        title: '账单消耗',
        type: 'item',
        url: '/profile/consume/index',
        icon: IconClipboardData,
        breadcrumbs: false
      },
      ...(isShowTeamMenu()
        ? [
            {
              id: 'team_manage',
              title: '我的团队',
              type: 'item',
              url: '/profile/team_manage',
              icon: IconUsers,
              breadcrumbs: false,
              isAdmin: false
            }
          ]
        : []),
      {
        id: 'rights',
        title: '我的权益',
        type: 'item',
        url: '/profile/rights',
        icon: rights,
        breadcrumbs: false
      },
    ]
  };
};

export { getProfileMenu };
