import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import fold from 'assets/images/icons/fold.svg';
import expend from 'assets/images/icons/expend.svg';
import { SET_MENU } from 'store/actions';

const CardStyle = styled(Card)(() => ({
  background: 'transparent',
  //marginBottom: '22px',
  overflow: 'hidden',
  position: 'relative'
}));

const MenuCard = () => {
  const dispatch = useDispatch();
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  return (
    <CardStyle>
      <Typography
        variant="body1"
        sx={{ mx: '20px', overflow: 'hidden', height: '36px', width: '100%', display: 'flex', whiteSpace: 'nowrap' }}
        onClick={handleLeftDrawerToggle}
      >
        <img
          src={leftDrawerOpened ? fold : expend}
          style={{ height: '18px', verticalAlign: 'text-bottom', marginRight: '8px', alignItems: 'center' }}
        />
        {leftDrawerOpened ? '收起导航' : ''}
      </Typography>
    </CardStyle>
  );
};

export default MenuCard;
