import { lazy } from 'react';

// project imports
import DocLayout from 'layout/DocLayout';
import Loadable from 'ui-component/Loadable';

// document routing
const Interface = Loadable(lazy(() => import('views/Document/Interface')));

const DocRoutes = {
  path: '/panel',
  element: <DocLayout />,
  children: [

    {
      path: 'interface-doc',
      element: <Interface />
    },

    {
      path: 'question-doc',
      element: <Interface />
    },

    {
      path: 'logs-doc',
      element: <Interface />
    },

  ]
};

export default DocRoutes;
