import { useSelector } from 'react-redux';
import { useEffect, useContext, useState } from 'react';
import { UserContext } from 'contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'antd';
import { isRoot } from 'utils/common';

const AuthGuard = ({ children }) => {
  const account = useSelector((state) => state.account);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const hideModel = () => setIsModalOpen(false);
  const goPersonalAuthPage = () => {
    hideModel();
    navigate('/personal/auth');
  };

  const goentErpriseAuthPage = () => {
    hideModel();
    navigate('/enterprise/auth');
  };
  const { isUserLoaded } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (isUserLoaded && !account.user) {
      navigate('/login/index');
      return;
    }
    if (!isRoot() && account.user) {
      setTimeout(() => {
        if (account.user?.personal_auth_status == 0 && account.user?.enterprise_auth_status == 0) {
          showModal();
        }
      }, 1000);
    }
  }, [account, navigate, isUserLoaded]);

  return (
    <>
      <Modal
        zIndex={98}
        centered
        title="提示"
        open={isModalOpen}
        closable={false}
        footer={() => (
          <>
            <Button onClick={() => goPersonalAuthPage()} type="primary">
              个人认证
            </Button>
            <Button onClick={() => goentErpriseAuthPage()} type="primary">
              企业认证
            </Button>
          </>
        )}
      >
        <p>
          亲爱的用户，根据国家相关政策要求，为保障您的账户安全和提供更优质的服务，您需要先完成身份认证。认证过程简单快捷，请放心操作。感谢您的理解与配合！
        </p>
      </Modal>
      {children}
    </>
  );
};

export default AuthGuard;
