import { lazy } from 'react';
import ProfileLayout from 'layout/ProfileLayout';
import Loadable from 'ui-component/Loadable';

const Topup = Loadable(lazy(() => import('views/Topup')));
const ProfileNew = Loadable(lazy(() => import('views/Profile_new')));
const OrderManageNew = Loadable(lazy(() => import('views/OrderManage_new')));
const OrderConsume = Loadable(lazy(() => import('views/OrderConsume')));
const TeamManage = Loadable(lazy(() => import('views/TeamManage_new')));
const PrivacyAgreement = Loadable(lazy(() => import('views/PrivacyAgreement')));
const UserAgreement = Loadable(lazy(() => import('views/UserAgreement')));
const TeamLayout = Loadable(lazy(() => import('views/TeamManage_new/component/TeamLayout')));
const TeamMemberList = Loadable(lazy(() => import('views/TeamManage_new/TeamMemberList')));
const ManageTeam = Loadable(lazy(() => import('views/TeamManage_new/ManageTeam')));
const ReviewMember = Loadable(lazy(() => import('views/TeamManage_new/ReviewMember')));
const AuditTeam = Loadable(lazy(() => import('views/TeamManage_new/AuditTeam')));
const Rights = Loadable(lazy(() => import('views/Rights/index')));

const ProfileRoutes = {
  path: '/profile',
  element: <ProfileLayout />,
  children: [
    {
      path: 'order/manage/index',
      element: <OrderManageNew />
    },
    {
      path: 'consume/index',
      element: <OrderConsume />
    },
    {
      path: 'index',
      element: <ProfileNew />
    },
    {
      path: 'topup/index',
      element: <Topup />
    },
    {
      path: 'team_manage',
      element: <TeamLayout />,
      children: [
        {
          path: '',
          element: <TeamManage />
        },
        {
          path: 'team_member_list',
          element: <TeamMemberList />
        },
        {
          path: 'manage_team',
          element: <ManageTeam />
        },
        {
          path: 'review_member',
          element: <ReviewMember />
        },
        {
          path: 'audit_team',
          element: <AuditTeam />
        }
      ]
    },
    {
      path: 'rights',
      element: <Rights />
    },
    {
      path: 'privacy/agreement/index',
      element: <PrivacyAgreement />
    },
    {
      path: 'user/agreement/index',
      element: <UserAgreement />
    }
  ]
};

export default ProfileRoutes;
