// material-ui
import { Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
// project imports
import NavGroup from './NavGroup';
import { getMenuItems } from 'menu-items/doc';
import { isAdmin, isRoot } from 'utils/common';
import searchImg from 'assets/images/model/search.svg';
import { Input, Empty } from 'antd';
import { getDocMenu } from 'menu-items/doc_menu';
const MenuList = () => {
  const userIsAdmin = isAdmin();
  const userIsRoot = isRoot();
  const menuItem = getMenuItems();
  const [filteredData, setFilteredData] = useState(menuItem.items);


  const inputRef = useRef(null);
  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 'k' || e.key === 'K') {
        e.preventDefault();
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  const search = (keyword) => {
    if (keyword.trim() == '') {
      setFilteredData(menuItem.items)
      return
    }
    let result = filterDataByKeyword(menuItem.items[0].children, keyword)
    let res = filterDuplicateById(result);
    let menus = getDocMenu()
    menus.children = res
    setFilteredData([menus])
  }

  const filterDataByKeyword = (data, keyword) => {
    const result = [];
    data.forEach((item) => {
      if (item.title.includes(keyword)) {
        result.push(item);
      }
      if (item.children && item.children.length > 0) {
        const filteredChildren = filterChildrenByKeyword(item.children, keyword);
        if (filteredChildren.length > 0) {
          result.push({ ...item, children: filteredChildren });
        }
      }
    });
    return result;
  }

  const filterChildrenByKeyword = (children, keyword) => {
    const result = [];
    children.forEach((child) => {
      if (child.title.includes(keyword)) {
        result.push(child);
      }
      if (child.children && child.children.length > 0) {
        const filteredSubChildren = filterChildrenByKeyword(child.children, keyword);
        if (filteredSubChildren.length > 0) {
          result.push({ ...child, children: filteredSubChildren });
        }
      }
    });
    return result;
  }

  const filterDuplicateById = (data) => {
    const seenIds = new Set();
    const result = [];
    data.forEach((item) => {
      if (!seenIds.has(item.id)) {
        seenIds.add(item.id);
        const newItem = { ...item };
        if (newItem.children && newItem.children.length > 0) {
          newItem.children = filterDuplicateById(newItem.children);
        }
        result.push(newItem);
      }
    });
    return result;
  };

  return (
    <>
      <Input placeholder="搜索（Ctrl + K）" ref={inputRef} allowClear onChange={(keyword) => search(keyword.target.value)} style={{ margin: "16px 0 7px 0", backgroundColor: '#F4F7FF' }} prefix={<img src={searchImg}  ></img>} />

      {filteredData.map((item) => {
        if (item.type !== 'group') {
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
        }

        const filteredChildren = item.children
          .filter((child) => !child.isAdmin || userIsAdmin)
          .filter((child) => !child.isRoot || userIsRoot);

        if (filteredChildren.length === 0) {
          return null;
        }

        return <NavGroup key={item.id} item={{ ...item, children: filteredChildren }} />;
      })}
      {filteredData[0].children.length == 0 && <Empty style={{fontSize:'12px',marginTop:'80px',color:'#00002240'}} image={Empty.PRESENTED_IMAGE_SIMPLE}  description='没有找到相关内容'/>}
    </>
  );
};

export default MenuList;
