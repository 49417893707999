/**
 * Typography used in theme
 * @param {JsonObject} theme theme customization object
 */

export default function themeTypography(theme) {
  return {
    fontFamily: theme?.customization?.fontFamily,
    h6: {
      fontWeight: 500,
      color: theme.heading,
      fontSize: '0.75rem'
    },
    h5: {
      fontSize: '0.875rem',
      color: theme.heading,
      fontWeight: 500
    },
    h4: {
      fontSize: '1rem',
      color: theme.heading,
      fontWeight: 600
    },
    h3: {
      fontSize: '1.25rem',
      color: theme.heading,
      fontWeight: 600
    },
    h2: {
      fontSize: '1.5rem',
      color: theme.heading,
      fontWeight: 700
    },
    h1: {
      fontSize: '2.125rem',
      color: theme.heading,
      fontWeight: 700
    },
    subtitle1: {
      fontSize: '0.875rem',
      fontWeight: 500,
      color: theme.textDark
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      color: theme.primaryTextSecondary
    },
    subtitle3: {
      fontSize: '0.875rem',
      fontWeight: 400,
      color: theme.grayText
    },
    subtitle4: {
      fontSize: '18px',
      fontWeight: 500,
      color: theme.textDark
    },
    subtitle5:{
      fontSize: '18px',
      fontWeight: 600,
      color:'#000000'
    },
    formTitle:{
      fontSize: '14px',
      fontWeight: 400,
      color:'#000022E8'
    },
    mainTitle:{
      fontSize: '18px',
      fontWeight: 500,
      color:'#121313'
    },
    subTitle:{
      fontSize: '16px',
      fontWeight: 400,
      color:'#000000'
    },
    formItem:{
      fontSize: '14px',
      fontWeight: 400,
      color:'#00002280',
      textAlign: 'right'
    },
    formValue:{
      fontSize: '14px',
      fontWeight: 400,
      color:'#000022D9'
    },
    caption: {
      fontSize: '0.75rem',
      color: theme.darkTextSecondary,
      fontWeight: 400
    },
    body1: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.334em'
    },
    body2: {
      letterSpacing: '0em',
      fontWeight: 400,
      lineHeight: '1.5em',
      color: theme.darkTextPrimary
    },
    button: {
      textTransform: 'capitalize'
    },
    customInput: {
      marginTop: 2,
      //marginBottom: 2,
      '& > label': {
        top: 23,
        left: 0,
        color: theme.grey500,
        '&[data-shrink="false"]': {
          top: 5
        }
      },
      '& > div > input': {
       // padding: '30.5px 14px 11.5px !important'
      },
      '& legend': {
        display: 'none'
      },
      '& fieldset': {
        top: 0
      }
    },
    formInput: {
      borderRadius: '6px',
      background:'none',
      height: '40px',
      '.search': {
        display: 'none'
      },
    
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid ',
        borderColor: theme.primaryColor,
      },
      '& .MuiOutlinedInput-input': {
        '&::placeholder': {
          //color: theme.palette.primary.textDark,
          //opacity: 1
        },
        background: 'none',
      }
    },
    loginFormInput: {
      height: '48px'
    },

  //下拉框通用样式
    formSelect:{
      borderRadius: '6px',
      background:'none',
      height: '40px',
      '& .MuiSelect-select': {  
        //backgroundColor: 'transparent',  // 确保下拉箭头区域背景透明  
        background:'none',
      },  
      '& .MuiInputBase-input': {  
        color: '#697586',
       // background:'transparent !important'
      },  
      '& .MuiSelect-icon': {  
        color: '#697586',  // 自定义下拉箭头颜色  
      },      
    },
    otherInput: {
      marginTop: 1,
      marginBottom: 1
    },
    commonInput:{
      borderRadius: '6px',
      height: '40px',
      background:'none',
      '& .MuiOutlinedInput-input': {
        background: 'none',
      },
      '&:-webkit-autofill': {
        '-webkit-box-shadow': '0 0 0 1000px transparent inset',
        '-webkit-text-fill-color': 'inherit',
      },
    },
    mainContent: {
      backgroundColor: theme.background,
      width: '100%',
      minHeight: 'calc(100vh - 56px)',
      flexGrow: 1,
      padding: '20px',
      marginTop: '56px',
      //marginRight: '20px',
      borderRadius: `${theme?.customization?.borderRadius}px`
    },
    menuCaption: {
      fontSize: '0.875rem',
      fontWeight: 500,
      color: theme.heading,
      padding: '6px',
      textTransform: 'capitalize',
      marginTop: '10px'
    },
    subMenuCaption: {
      fontSize: '0.6875rem',
      fontWeight: 500,
      color: theme.darkTextSecondary,
      textTransform: 'capitalize'
    },
    commonAvatar: {
      cursor: 'pointer',
      borderRadius: '8px'
    },
    smallAvatar: {
      width: '22px',
      height: '22px',
      fontSize: '1rem'
    },
    mediumAvatar: {
      width: '34px',
      height: '34px',
      fontSize: '1.2rem'
    },
    largeAvatar: {
      width: '44px',
      height: '44px',
      fontSize: '1.5rem'
    },
    menuButton: {
      color: theme.menuButtonColor,
      background: theme.menuButton
    },
    menuChip: {
      background: theme.menuChip
    },
    CardWrapper: {
      backgroundColor: theme.mode === 'dark' ? theme.colors.darkLevel2 : theme.colors.primaryDark
    },
    SubCard: {
      border: theme.mode === 'dark' ? '1px solid rgba(227, 232, 239, 0.2)' : '1px solid rgb(227, 232, 239)'
    },

    MainBtn: {
      fontSize: '0.875rem',
      fontWeight: 400,
      color: 'red'
    },
    CommonBtn:{
      border: '1px #0000221F solid',
      boxShadow: 'none',
      backgroundColor:theme.paper, // 自定义背景色  
      color: theme.textDark, // 自定义文字颜色   textDark
      '&:hover': {  
       backgroundColor:theme.paper, // 悬停时的背景色 
          boxShadow: 'none',
        color: theme.textDark,
      }, 
      // padding: '10px 0',
      fontSize: '14px',
      borderRadius:'6px',
      height:'32px'
    },

  searchBox:{
    background:'none',
    borderRadius: '6px' ,
    height:'35px',
    width:'200px',
    '.search':{
      display:'none'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor:'none',
      border:'none'
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {  
      border:'1px solid ',
      borderColor:theme.primaryColor,
      '.search':{
        display:'block'
      },
    },
    '&.Mui-focused': {  
      '&::placeholder': {  
        color: 'gray',
        opacity:0.9
      },
    },
    '& .MuiOutlinedInput-input': {  
      '&::placeholder': {  
        color: theme.textDark,
        opacity:0.8
      },
      // '&:hover': {
      //   '&::placeholder': {  
      //     color: theme.palette.primary.main,
      //     opacity:1
      //   },
      // },
      background:'none',
    }
  }


  };

}
