import axios from 'axios';
import { API } from 'utils/api';


export const getTeamInfo = async () => {
  return await API.get(`/api/teams/check_user_status`);
};

export const getTeamUserList = async (query) => {
  const {pageSize,pageNum,searchKeyword,id} = query;
  return await API.get(`/api/teams/members?page_size=${pageSize}&p=${pageNum}&id=${id}&username=${searchKeyword}`);
  //return await API.get(`/api/teams/members/${query}`);
};

export const getTeamInfoBycode = async (code) => {
  return await API.get(`/api/teams/by_invitation_code?invitation_code=${code}`);
};


export const getJoinTeamBycode = async (code) => {
  return await API.post(`/api/teams/join`,{"invitationCode":code});
};

export const handleModelService = async (param) => {
  return await API.delete(`/api/teams/members?team_id=${param.team_id}&user_id=${param.user_id}`);
};

export const getModelList = async () => {
  return await API.post('/api/infiniGence/finetune/model/list', {});
};


export const loadTeamModels = async () => {
  const res = await API.get(`/api/teams/models`);
  return res;
};