import { lazy } from 'react';

// project imports
import HomeLayout from 'layout/HomeLayout';
import Loadable from 'ui-component/Loadable';

const Channel = Loadable(lazy(() => import('views/Channel')));
const Log = Loadable(lazy(() => import('views/Log')));
const Redemption = Loadable(lazy(() => import('views/Redemption')));
const Setting = Loadable(lazy(() => import('views/Setting')));
const TokenNew = Loadable(lazy(() => import('views/Token_new')));
const Topup = Loadable(lazy(() => import('views/Topup')));
const User = Loadable(lazy(() => import('views/User')));
const Profile = Loadable(lazy(() => import('views/Profile')));
const ProfileNew = Loadable(lazy(() => import('views/Profile_new')));
const NotFoundView = Loadable(lazy(() => import('views/Error')));

// dashboard routing
const Dashboard = Loadable(lazy(() => import('views/Dashboard')));
const ModelSquare_new = Loadable(lazy(() => import('views/ModelSquare_new')));
const ModelSquareManage = Loadable(lazy(() => import('views/ModelSquareManage')));
const ModelTagManage = Loadable(lazy(() => import('views/ModelTagManage')));
const ModelDetailsNew = Loadable(lazy(() => import('views/ModelDetails_new')));
const ExplorationCenterChatBot = Loadable(lazy(() => import('views/ExplorationCenter/ChatBot')));
const StatisticsPanel = Loadable(lazy(() => import('views/StatisticsPanel')));
const StatisticsPanelManage = Loadable(lazy(() => import('views/StatisticsPanelManage')));
const TextGenerateImage = Loadable(lazy(() => import('views/ExplorationCenter/TextGenerateImage')));
const TextGenerateVoice = Loadable(lazy(() => import('views/ExplorationCenter/TextGenerateVoice')));
const ModelFineTuning = Loadable(lazy(() => import('views/ModelFineTuning_bailian')));
const ModelFineTuningNew = Loadable(lazy(() => import('views/ModelFineTuning')));
const ModelFineService = Loadable(lazy(() => import('views/ModelFineService')));

const ModelFineTuningCreate = Loadable(lazy(() => import('views/ModelFineTuning_bailian/create')));
const ModelFineTuningCreateNew = Loadable(lazy(() => import('views/ModelFineTuning/create')));

const ModelFinetuneDetail = Loadable(lazy(() => import('views/ModelFineTuning_bailian/detail')));
const ModelFinetuneDetailNew = Loadable(lazy(() => import('views/ModelFineTuning/detail')));
const ModelFineServiceCreateNew = Loadable(lazy(() => import('views/ModelFineService/create')));
const ModelFineServiceDetailNew = Loadable(lazy(() => import('views/ModelFineService/detail')));
const TeamManage = Loadable(lazy(() => import('views/TeamManage_new')));
const OrderManageNew = Loadable(lazy(() => import('views/OrderManage_new')));
const UsageStatistics = Loadable(lazy(() => import('views/UsageStatistics')));
const TeamUserToken = Loadable(lazy(() => import('views/TeamManage/UserToken')));
const EnterpriseAuthManage = Loadable(lazy(() => import('views/Identification/EnterpriseAuthManage')));
const CueWordProject = Loadable(lazy(() => import('views/CueWordProject')));
const Agent = Loadable(lazy(() => import('views/Agent')));
const Datasets = Loadable(lazy(() => import('views/Datasets')));

const MainRoutes = {
  path: '/panel',
  element: <HomeLayout />,
  children: [
    {
      path: 'index',
      element: <Dashboard />
    },
    {
      path: 'statistics/index',
      element: <StatisticsPanel />
    },

    {
      path: 'dashboard/index',
      element: <Dashboard />
    },
    {
      path: 'modelsquare/index',
      element: <ModelSquare_new />
    },
    {
      path: 'cuewordproject/index',
      element: <CueWordProject />
    },
    {
      path: 'agent/index',
      element: <Agent />
    },
    {
      path: 'datasets/index',
      element: <Datasets />
    },
    {
      path: 'modelsquare-manage/index',
      element: <ModelSquareManage />
    },
    {
      path: 'order/manage_new/index',
      element: <OrderManageNew />
    },
    {
      path: 'modeltag/manage/index',
      element: <ModelTagManage />
    },
    {
      path: 'usage/index',
      element: <UsageStatistics />
    },
    {
      path: 'user_index_distribution/index',
      element: <StatisticsPanelManage />
    },
    {
      path: 'exploration_center/index',
      element: <ExplorationCenterChatBot />
    },
    {
      path: 'exploration_center/text2img/index',
      element: <TextGenerateImage />
    },
    {
      path: 'exploration_center/text2voice/index',
      element: <TextGenerateVoice />
    },
    {
      path: 'modelsquare_new/details/:id',
      element: <ModelDetailsNew />
    },
    {
      path: 'model/fine_tuning',
      element: <ModelFineTuning />
    },
    {
      path: 'model/fine_tuning_pro',
      element: <ModelFineTuningNew />
    },
    {
      path: 'model/fine_tuning/create',
      element: <ModelFineTuningCreate />
    },
    {
      path: 'model/fine_tuning_pro/create',
      element: <ModelFineTuningCreateNew />
    },
    {
      path: 'model/fine_tuning_pro/detail/:id',
      element: <ModelFinetuneDetailNew />
    },
    {
      path: 'model/fine_service',
      element: <ModelFineService />
    },
    {
      path: 'model/fine_service/create/:id',
      element: <ModelFineServiceCreateNew />
    },
    {
      path: 'model/fine_service/detail/:id',
      element: <ModelFineServiceDetailNew />
    },
    {
      path: '/panel/team_manage/index',
      element: <TeamManage />
    },
    {
      path: '/panel/team_usertoken/index',
      element: <TeamUserToken />
    },
    {
      path: '/panel/auth/enterprise/manage/index',
      element: <EnterpriseAuthManage />
    },
    {
      path: 'channel/index',
      element: <Channel />
    },
    {
      path: 'log/index',
      element: <Log />
    },
    {
      path: 'redemption/index',
      element: <Redemption />
    },
    {
      path: 'setting/index',
      element: <Setting />
    },
    {
      path: 'token/index',
      element: <TokenNew />
    },
    {
      path: 'topup/index',
      element: <Topup />
    },
    {
      path: 'user/index',
      element: <User />
    },
    {
      path: 'profile/index',
      element: <Profile />
    },
    {
      path: 'profile_new/index',
      element: <ProfileNew />
    },
    {
      path: '404/index',
      element: <NotFoundView />
    }
  ]
};

export default MainRoutes;
