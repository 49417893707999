import { useState, useRef, useEffect } from 'react';
import { hidePhoneNumber, randomColor } from 'utils/common';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import { IconUsers, IconClipboardData, IconFileDescription, IconCoinYen, IconUser, IconLogout } from '@tabler/icons-react';
import {
  Avatar,
  Chip,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
  Divider,
  Box,
  Grid
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import useLogin from 'hooks/useLogin';

// assets
import { IconChevronDown } from '@tabler/icons-react';
import userSet from 'assets/images/users/user-set.svg';
import bill from 'assets/images/users/bill.svg';
import recharge from 'assets/images/users/recharge.svg';
import loginout from 'assets/images/users/logout.svg';
import consume from 'assets/images/users/consume.svg';

const ProfileSection = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const customization = useSelector((state) => state.customization);
  const { logout } = useLogin();
  const [open, setOpen] = useState(false);
  const [userbg, setUserbg] = useState(randomColor());
  const userinfo = JSON.parse(localStorage.getItem('userinfo'));
  const anchorRef = useRef(null);
  const handleLogout = async () => {
    logout();
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const styles = {
    text: {
      maxWidth: '120px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  };

  return (
    <>
      <Chip
        sx={{
          height: '48px',
          alignItems: 'center',
          ml: '10px',
          borderRadius: '0',
          borderColor: 'white',
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: 'transparent',
            background: 'transparent',
            color: theme.palette.primary.light,
            '&:hover': {
              background: 'transparent' // 自定义hover时的背景色
            }
          },
          '& .MuiChip-label': {
            lineHeight: 0
          }
        }}
        icon={
          userinfo?.avatar ? (
            <Avatar
              src={userinfo.avatar}
              sx={{
                ...theme.typography.mediumAvatar,
                margin: '8px 0 8px 0 !important',
                cursor: 'pointer',
                height: '30px',
                width: '30px'
              }}
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              style={{ background: '#fff' }}
            ></Avatar>
          ) : (
            <Avatar
              sx={{
                color: 'white !important',
                bgcolor: `${userbg}`,
                height: '25px',
                width: '25px',
                alignItems: 'center',
                fontSize: '14px'
              }}
            >
              {userinfo?.username.charAt(0)}
            </Avatar>
          )
        }
        label={
          <>
            <Typography variant="subtitle2" color="#000022D9" sx={{ textDecoration: 'none', cursor: 'pointer', verticalAlign: 'baseline' }}>
              欢迎，{userinfo?.username}
              <IconChevronDown
                style={{ width: '15px', height: '15px', verticalAlign: 'middle', marginLeft: '10px' }}
                stroke={1.5}
                size="1.5rem"
                color={'#000022D9'}
              />
            </Typography>
          </>
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 8]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper sx={{ width: 236 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]} sx={{ px: '16px' }}>
                  <Box sx={{ display: 'flex', pt: '16px', px: '8px' }}>
                    <Box sx={{ mr: 1 }}>
                      {userinfo?.avatar ? (
                        <Avatar src={userinfo.avatar} sx={{ width: '48px', height: '48px' }}></Avatar>
                      ) : (
                        <Avatar
                          sx={{ color: 'white !important', bgcolor: `${userbg}`, height: '48px', width: '48px', alignItems: 'center' }}
                        >
                          {userinfo?.username.charAt(0)}
                        </Avatar>
                      )}
                    </Box>
                    <Box>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Box sx={{ color: '#222929', fontSize: '16px', ...styles.text }}>{userinfo?.username}</Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box sx={{}}>{userinfo?.phone ? hidePhoneNumber(userinfo?.phone) : '-'}</Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <List
                    component="nav"
                    sx={{
                      width: '100%',
                      maxWidth: 350,
                      minWidth: 150,
                      backgroundColor: theme.palette.background.paper,
                      borderRadius: '10px',
                      [theme.breakpoints.down('md')]: {
                        minWidth: '100%'
                      },
                      '& .MuiListItemButton-root': {
                        borderRadius: '6px',
                        height: 36,
                        mb: '8px',
                        px: '8px',
                        mt: '8px',
                        '&:hover': {
                          backgroundColor: '#F1F2F6', // 悬停时的背景色
                          boxShadow: 'none'
                        }
                      }
                    }}
                  >
                    <ListItemButton sx={{ borderRadius: `${customization.borderRadius}px` }} onClick={() => navigate('/profile/index')}>
                      <ListItemIcon>
                        <IconUser stroke={1.5} size="1.3rem"></IconUser>
                      </ListItemIcon>
                      <ListItemText primary={<Typography variant="body2">账户设置</Typography>} />
                    </ListItemButton>
                    <ListItemButton
                      sx={{ borderRadius: `${customization.borderRadius}px` }}
                      onClick={() => navigate('/profile/topup/index')}
                    >
                      <ListItemIcon>
                        <IconCoinYen stroke={1.5} size="1.3rem"></IconCoinYen>
                      </ListItemIcon>
                      <ListItemText primary={<Typography variant="body2">充值兑换</Typography>} />
                    </ListItemButton>

                    <ListItemButton
                      sx={{ borderRadius: `${customization.borderRadius}px` }}
                      onClick={() => navigate('/profile/order/manage/index')}
                    >
                      <ListItemIcon>
                        {/* <img src={bill} alt="bill" style={{ height: '17px' }} /> */}
                        <IconFileDescription stroke={1.5} size="1.3rem" />
                      </ListItemIcon>
                      <ListItemText primary={<Typography variant="body2">账单明细</Typography>} />
                    </ListItemButton>
                    <ListItemButton
                      sx={{ borderRadius: `${customization.borderRadius}px` }}
                      onClick={() => navigate('/profile/consume/index')}
                    >
                      <ListItemIcon>
                        <IconClipboardData stroke={1.5} size="1.3rem" />
                      </ListItemIcon>
                      <ListItemText primary={<Typography variant="body2">账单消耗</Typography>} />
                    </ListItemButton>
                    <ListItemButton
                      sx={{ borderRadius: `${customization.borderRadius}px` }}
                      onClick={() => navigate('/profile/team_manage')}
                    >
                      <ListItemIcon>
                        <IconUsers stroke={1.5} size="1.3rem" />
                        {/* <img src={userSet} alt="bill" style={{ height: '17px' }} /> */}
                      </ListItemIcon>
                      <ListItemText primary={<Typography variant="body2">我的团队</Typography>} />
                    </ListItemButton>

                    <Divider sx={{ mt: 0.25, mb: 1.25 }} />
                    <ListItemButton sx={{ borderRadius: `${customization.borderRadius}px` }} onClick={handleLogout}>
                      <ListItemIcon>
                        {/* <img src={loginout} alt="logout" style={{ height: '17px' }} /> */}
                        <IconLogout stroke={1.5} size="1.3rem" />
                      </ListItemIcon>
                      <ListItemText primary={<Typography variant="body2">退出登录</Typography>} />
                    </ListItemButton>
                  </List>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
