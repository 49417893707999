export default function componentStyleOverrides(theme) {
  const bgColor = theme.mode === 'dark' ? theme.backgroundDefault : theme.colors?.grey50;
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          borderRadius: '4px',
          height: '32px',
          boxShadow: 'none',
          '&.Mui-disabled': {
            color: theme.colors?.grey600
          },
          '&:hover': {
            backgroundColor: '#4674F6', //theme.colors?.primaryColor,
            color: theme.primaryLight,
            '& .MuiListItemIcon-root': {
              color: theme.primaryLight
            },
            boxShadow: 'none'
          }
        }
      }
    },
    //MuiAutocomplete-popper MuiPopover-root
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          // 继承 MuiPopover-root
          boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
          borderRadius: '12px',
          color: '#364152'
        },
        listbox: {
          // 继承 MuiPopover-root
          padding: '0px',
          paddingTop: '8px',
          paddingBottom: '8px'
        },
        option: {
          fontSize: '16px',
          fontWeight: '400',
          lineHeight: '1.334em',
          alignItems: 'center',
          paddingTop: '6px',
          paddingBottom: '6px',
          paddingLeft: '16px',
          paddingRight: '16px'
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          // boxShadow: '2px 2px 3px #04183805',
          boxShadow: '1px 1px 4px #04183805' /* 水平偏移、垂直偏移、模糊半径、阴影颜色 */,
          borderRadius: '6px'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          // color: 'white',
          borderRadius: '6px',
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          '&:hover': {
            backgroundColor: theme.colors?.grey200
          }
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none'
        },
        rounded: {
          borderRadius: `${theme?.customization?.borderRadius}px`
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.colors?.textDark,
          padding: '24px'
        },
        title: {
          fontSize: '1.125rem'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px'
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '24px'
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          padding: '10px 0',
          //height:'40px',
          '&.Mui-selected': {
            color: theme.menuSelected,
            borderRadius: '6px',
            backgroundColor: theme.menuSelectedBack,
            '&:hover': {
              backgroundColor: theme.menuSelectedBack
            },
            '& .MuiListItemIcon-root': {
              color: theme.menuSelected
            }
          },
          '&:hover': {
            borderRadius: '6px',
            backgroundColor: theme.menuSelectedBack,
            color: theme.menuSelected,
            '& .MuiListItemIcon-root': {
              color: theme.menuSelected
            }
          }
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          minWidth: '36px'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.textDark
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: theme.textDark,
          '&::placeholder': {
            color: theme.darkTextSecondary,
            fontSize: '0.875rem'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // background: bgColor,
          borderRadius: `${theme?.customization?.borderRadius}px`,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#0000221F', //theme.colors?.grey400,
            borderWidth: '1px', // 设置边框宽度
            borderRadius: '8px' // 设置圆角
          },
          '&:hover $notchedOutline': {
            //borderColor: theme.colors?.primaryLight
          },
          '&.MuiInputBase-multiline': {
            padding: 1
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.colors?.primaryColor,
            borderWidth: '1px'
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.colors?.primaryColor
          }
        },
        input: {
          fontWeight: 500,
          //background: bgColor,
          padding: '15.5px 14px',
          borderRadius: `${theme?.customization?.borderRadius}px`,
          '&.MuiInputBase-inputSizeSmall': {
            padding: '10px 14px',
            '&.MuiInputBase-inputAdornedStart': {
              paddingLeft: 0
            }
          }
        },
        inputAdornedStart: {
          paddingLeft: 4
        },
        notchedOutline: {
          borderRadius: `${theme?.customization?.borderRadius}px`
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: theme.colors?.grey300
          }
        },
        mark: {
          backgroundColor: theme.paper,
          width: '4px'
        },
        valueLabel: {
          color: theme?.colors?.primaryLight
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.divider,
          opacity: 1
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: theme.colors?.primaryDark,
          background: theme.colors?.primary200
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-deletable .MuiChip-deleteIcon': {
            // color: 'inherit'
          },
          '&.MuiButtonBase-root.MuiChip-root:active': {
            boxShadow: 'none'
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid ' + theme.tableBorderBottom,
          textAlign: 'center'
        },
        head: {
          color: theme.darkTextSecondary,
          backgroundColor: theme.headBackgroundColor
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: theme.headBackgroundColor
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.colors.paper,
          background: theme.colors?.grey700
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: `
      .apexcharts-title-text {
          fill: ${theme.textDark} !important
        }
      .apexcharts-text {
        fill: ${theme.textDark} !important
      }
      .apexcharts-legend-text {
        color: ${theme.textDark} !important
      }
      .apexcharts-menu {
        background: ${theme.backgroundDefault} !important
      }
      .apexcharts-gridline, .apexcharts-xaxistooltip-background, .apexcharts-yaxistooltip-background {
        stroke: ${theme.divider} !important;
      }
      `
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: theme.colors?.primaryTextSecondary,
          padding: 0,
          borderRadius: '8px' // 设置圆角
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        // 你也可以为特定的变体（如错误状态）定义样式
        contained: {
          // 如果需要，为在FormControl内且为contained模式的Input定义的样式
          margin: 0,
          marginTop: '4px'
        }
      }
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          // backgroundColor: 'purple',
          color: theme.textDark,
          '&:hover': {
            backgroundColor: '#0E3E7614'
          },
          margin: '4px',
          borderRadius: '6px'
        }
      }
    },

    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: '0 1px 4px rgba(0, 0, 0, 0.2)',
          borderRadius: '0 0 6px 6px'
        }
      }
    }
  };
}
