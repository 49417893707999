import { isShowTeamMenu } from 'utils/common';
import { IconUsers, IconClipboardData, IconFileDescription, IconCoinYen, IconUser } from '@tabler/icons-react';

const getDocMenu = () => {
  return {
    id: 'panel',
    type: 'group',
    children: [
      {
        id: 'exploration_center',
        title: '接口文档',
        type: 'collapse',
        // type: 'item',
        // url: '/panel/exploration_center',
        icon: IconFileDescription,
        breadcrumbs: false,
        isAdmin: false,
        children: [
          {
            id: 'chat_bot',
            title: '文本对话',
            type: 'item',
            url: '/panel/interface-doc',
            icon: IconFileDescription,
            breadcrumbs: false,
            isAdmin: false
          },
          {
            id: 'text2image',
            title: '图片生成',
            type: 'item',
            url: '/panel/img-doc',
            icon: IconFileDescription,
            breadcrumbs: false,
            isAdmin: false
          },
          {
            id: 'text2language',
            title: '语音生成',
            type: 'item',
            url: '/panel/question-doc',
            icon: IconFileDescription,
            breadcrumbs: false,
            isAdmin: false
          },
          {
            id: 'model-list',
            title: '模型列表',
            type: 'item',
            url: '/panel/question-doc',
            icon: IconFileDescription,
            breadcrumbs: false,
            isAdmin: false
          },{
            id: 'account',
            title: '账户余额',
            type: 'item',
            url: '/panel/question-doc',
            icon: IconFileDescription,
            breadcrumbs: false,
            isAdmin: false
          },
          {
            id: 'error-num',
            title: '错误码',
            type: 'item',
            url: '/panel/question-doc',
            icon: IconFileDescription,
            breadcrumbs: false,
            isAdmin: false
          }
        ],
        group: ''
      },
      {
        id: 'question',
        title: '常见问题',
        type: 'collapse',
      //  url: '/panel/question-doc',
        icon: IconFileDescription,
        breadcrumbs: false,
        children: [
          {
            id: 'ticket',
            title: '开发票',
            type: 'item',
            url: '/panel/question-doc',
            icon: IconFileDescription,
            breadcrumbs: false,
            isAdmin: false
          }
        ],
        group: ''
      },
      {
        id: 'logs',
        title: '公共日志',
        type: 'collapse',
        url: '/panel/doc2',
        icon: IconFileDescription,
        breadcrumbs: false,
        children: [
          {
            id: 'logs',
            title: 'V1.0.0',
            type: 'item',
            url: '/panel/logs-doc',
            icon: IconFileDescription,
            breadcrumbs: false,
            isAdmin: false
          }
        ],
      },
      
    ]
  };
};

export { getDocMenu };
